 
    /* Necessary for full page carousel*/
    html,
    body,
    header,
    .view {
      height: 100%;

    }
  
    @media (min-width: 451px) and (max-width: 740px) {
      html,
      body,
      header,
      .view {
        height: 500px;
      }
    }
    @media (min-width: 800px) and (max-width: 850px) {
      html,
      body,
      header,
      .view {
        height: 550px;
      }
    }

    /* Carousel*/
    .carousel,
    .carousel-item,
    .carousel-item.active {
      height: 100%;
    }

    .carousel-inner {
      height: 100%;
    }

    /*   .carousel-item:nth-child(1) {
  background-image: url("img/img/work.svg")  ;
    
       
      background-repeat: no-repeat; 
      
    }
*/
    .carousel-item:nth-child(2) {
      background-image: url("https://mdbootstrap.com/img/Photos/Others/images/89.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    .carousel-item:nth-child(3) {
      background-image: url("https://mdbootstrap.com/img/Photos/Others/images/90.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
      
      body{
          
          font-family: Sans-serif;
      }



      @media screen and (max-width: 600px) {
  .hide {
    display: none;

  }
  .body_cont
  {
margin-top: -500px;
    
  }
}


